<template>
      <div class="modal my-popups right fade show" id="manageProjectModal" role="dialog" style="display: block;">
            <div class="modal-dialog mw-1100" role="document">
                <div class="modal-content">
                    <div class="modal-header mheader-user">
                        <button type="button" class="close" @click="$emit('close')"><i class="fa fa-times" aria-hidden="true"></i></button>
                        <h4 class="modal-title" id="myModalLabel">{{ wizardTitle }}</h4>
                        <span class="user-guide" v-if="wizardType=='Project'">
                            <a class="ancuserguide" data-toggle="collapse" href="#collapseReply" role="button" aria-expanded="false" aria-controls="collapseExample">User Guide</a>
                            <div id="collapseReply" class="divancuserguide collapse">
                                <div id="scrollbarreplyuserguide" class="custom-scrollbar-js" style="overflow-x: hidden;">
                                    <div class="con">{{userGuide}}</div>
                                </div>
                            </div>
                        </span>
                    </div>
                    <div class="modal-body">  
<div v-if="wizardType=='Lead'">

    <ManageLead callFrom="Wizard" v-on:closewizard="$emit('close')"></ManageLead>
</div>  
<div v-else-if="wizardType=='User'">
  <AddUserWizards callFrom="Wizard" v-on:closewizard="$emit('close')"></AddUserWizards>
</div>            
<div v-else-if="wizardType=='Project'">
    <AddProjectWizards v-on:closewizard="$emit('close')" callFrom="Wizard"></AddProjectWizards>
</div> 
<div v-else-if="wizardType=='Product'">
 <ManageProduct callFrom="Wizard" v-on:closewizard="$emit('close')"></ManageProduct>
</div> 
<div v-else-if="wizardType=='Ticket'">
  <AddTicketing callFrom="Wizard" v-on:closewizard="$emit('close')"></AddTicketing>
</div>   
</div>
                </div>
            </div>
      </div>
      
</template>
<script>
import ManageLead from '../../views/CRM/Lead/Manage.vue'
import AddUserWizards from './AddUserWizards.vue';
import AddTicketing from './AddTicketing.vue';
import ManageProduct from '../../views/CRM/Product/Manage.vue';
import AddProjectWizards from './AddProjectWizards.vue';
export default {
    components: {
    ManageLead,
    AddUserWizards,
    ManageProduct,
    AddTicketing,
    AddProjectWizards
},
props:{
    wizardType:null//wizardType- User,Project,Lead,Ticket,Product
},
data(){
    return{
wizardTitle:'',
userGuide: ''
    }
},
created(){
this.GetWizardTitle();
},
methods:{
GetWizardTitle(){  
    switch(this.wizardType){
            case 'User':
            this.wizardTitle='Add User';
                break;
            case 'Project':
                this.wizardTitle='Project';
                break;
            case 'Lead':
                this.wizardTitle='Manage Lead';
                break;
            case 'Ticket':    
            this.wizardTitle='Add Ticket';        
                break;
            case 'Product':
                this.wizardTitle='Manage Product';
                break;
    }
}
}
}
</script>